const galleryItems = [
    {
        "src": "data/gallery/banner.jpeg",
        "width": 590,
        "height": 1280
    },
    {
        "src": "data/gallery/bottles.jpeg",
        "width": 1280,
        "height": 960
    },
    {
        "src": "data/gallery/candalier1.jpeg",
        "width": 960,
        "height": 1280
    },
    {
        "src": "data/gallery/candalier2.jpeg",
        "width": 768,
        "height": 1024
    },
    {
        "src": "data/gallery/img1.jpeg",
        "width": 854,
        "height": 1280
    },
    {
        "src": "data/gallery/img2.jpeg",
        "width": 854,
        "height": 1280
    },
    {
        "src": "data/gallery/img_1.jpeg",
        "width": 1080,
        "height": 1235
    },
    {
        "src": "data/gallery/img_2.jpeg",
        "width": 1209,
        "height": 1280
    },
    {
        "src": "data/gallery/img_3.jpeg",
        "width": 1080,
        "height": 1080
    },
    {
        "src": "data/gallery/img_4.jpeg",
        "width": 1280,
        "height": 1280
    },
    {
        "src": "data/gallery/wall_hanging.jpeg",
        "width": 1280,
        "height": 1280
    },
    {
        "src": "data/gallery/wall_hanging2.jpeg",
        "width": 1280,
        "height": 1206
    },
    {
        "src": "data/gallery/wallhang1.jpeg",
        "width": 1080,
        "height": 994
    }
];

export default galleryItems;