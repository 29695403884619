import React from 'react';
import Gallery from "react-photo-gallery";
import galleryItems from '../data/gallery_data';
import corousalItems from '../data/corousal_data';
import ImageGallery from '../components/image_gallery';

export default function Home() {
    return (
        <div>
            {/* <div>
                <ImageGallery images={corousalItems}/>
            </div> */}
            <h2>Welcome to the world of Fibre Art.</h2>
            <h2>We are here to introduce every one to the wonders of art of knots also known as Macrame.</h2>
            <hr  style={{ color: '#000000', backgroundColor: '#000000', height: .5, borderColor : '#000000'}}/>
            <h3>somethings we have done</h3>
            <div style={{padding: "20 px"}}>
                <Gallery photos={galleryItems}/>
            </div>
        </div>
    );
    //<DynamicCarousel/>;
}