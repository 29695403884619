import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Shop from '@material-ui/icons/Shop';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  card: {
    display: 'flex',
    padding: "20px",
    margin: "20px"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Store() {
  const classes = useStyles();

  return (
    <div>
        <div style={{display: 'flex', padding: '10px', alignContent: "center"}}>
            <Card className={classes.card}>
                <CardContent>
                    <Shop/>
                    <Typography className={classes.pos} color="textSecondary">
                        Shop our wonderful macrame products in our store
                    </Typography>
                    <Typography variant="h5" component="h2">
                        <a href='https://knottyfibres.myinstamojo.com/'>My Shop</a>
                    </Typography>
                </CardContent>
            </Card>
        </div>
    </div>
  );
}
