import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: '#a98d85',
      main: '#493f3c',
      dark: '#006db3',
      contrastText: '#fff',
    },
  },
});

export default theme;