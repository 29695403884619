import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import '../App.css';

export default class ImageGallery extends Component {

  constructor(props) {
    super(props);
    this.images = props.images;
    this.autoPlay = props.hasOwnProperty("autoPlay") ? props.autoPlay : false;
    this.interval = props.hasOwnProperty("interval") ? props.interval : 2000;
    this.infiniteLoop = props.hasOwnProperty("infiniteLoop") ? props.infiniteLoop : true;
    this.showThumbs = props.hasOwnProperty("showThumbs") ? props.showThumbs : true;
  }

  render() {
      return (
        <Carousel showThumbs={this.showThumbs} autoPlay={this.autoPlay} interval={this.interval}
         infiniteLoop={this.infiniteLoop} showStatus={false}>
          {this.images.map((element, index) => <div key={index}>
            <img src={element.src} className='slider-item' href={element.link} 
            style={{objectFit: 'scale-down', backgroundColor: '#efdad4'}}/>
          </div>)}
        </Carousel>
      )
  }
}