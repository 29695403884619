import React from 'react';
import clsx from 'clsx';
import logo from './kflogo.png';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import './App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Home from './pages/home';
import Catalogue from './pages/catalogue';
import About from './pages/about';
import Events from './pages/events';
import Store from './pages/store';
import Contact from './pages/contact';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import MiniDrawer from './components/drawer';
import AdminHome from './admin/pages/admin_home';
import customTheme from './theme/muiTheme'

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  palette: {
    primary: {
      light: '#63ccff',
      main: '#493f3c',
      dark: '#006db3',
      contrastText: '#fff',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function App() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose(){
    setOpen(false);
  }

  return (
    <ThemeProvider theme={customTheme}>
      <Router>
        <div className="App">
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <img src={logo} className="App-logo" alt="logo" />
            <Typography variant="h3" noWrap>
              Knotty Fibres
            </Typography>
          </Toolbar>
        </AppBar>
          <MiniDrawer open={open} handleDrawerParentClose={handleDrawerClose} />
          <main className={classes.content}>
            <div className={classes.toolbar} />
                <Route path="/" exact component={Home} />
                <Route path="/catalogue" component={Catalogue} />
                <Route path="/events" component={Events} />
                <Route path="/store" component={Store} />
                <Route path="/about" component={About} />
                <Route path="/contact" component={Contact} />
                <Route path="/admin" component={AdminHome} />
          </main>
        </div>
        </div>
      </Router>
    </ThemeProvider>

  );
}

export default App;
