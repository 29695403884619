
const items = [
    {
      title: "Fringed Goodness - Macrame Plant Hanger",
      images: [{ src: "data/items/PH0002/img1.jpg" }, { src: "data/items/PH0002/img2.jpg" }, { src: "data/items/PH0002/img3.jpg" }],
      description: "Plants hangers don't have to be stripped down products with no decorative elements. And a little fringe can go a long way, in revamping your green corners. These plant hangers, will keep your plant babies super-happy by providing them with-\
      * the soft feel of the fibre\
      * a good look from up-above\
      * sturdy base\
      \
      Please note that we are talking about a single plant hanger here, which is available, primarily, in white colour. So, message me to know the other available colours, which might come with a little extra charge.",
      materials: ["cotton linen mix", "dyed cotton", "synthetic cord", "steel ring", "Cotton"],
      dimentions: {height: "34 Inches", width: "10 Inches"},
      tags: ["handmade", "plant-hanger"],
      price: "1500",
      id: "PH0002" ,
      cover: { src: 'data/items/PH0002/img1.jpg' , width: 640, height: 640}
    },
    {
      title: "Cloves & Beads - Macrame Wall hanging Mini",
      images: [{ src: "data/items/WH0005/img1.jpg" }, { src: "data/items/WH0005/img2.jpg" }, { src: "data/items/WH0005/img3.jpg" }],
      description: "Acute cuteness can be delivered in simple and small designs too. Case in point- These Mini Cloves & Beads wall hangings. Made up of super soft cord, these can instantly uplift your small spaces. Ideal for study table, nursery, kid's rooms or as a gift.\
      \
      Please note that\
      1. There are 4 colours available- Gray, Dark Green, Blood Red & Mustard. Message me which colour you want, before making the payment.\
      2. Except the gray cord, all the other 3 cords, give a visible tie n dye type of effect in their open fringes.",
      materials: ["Cotton", "wooden dowel", "Dyed cotton", "Wooden beads"],
      dimentions: {height: "8 Inches", width: "8 Inches"},
      tags: ["handmade", "wall-hanging"],
      price: "500",
      id: "WH0005" ,
      cover: { src: 'data/items/WH0005/img2.jpg' , width: 1080, height: 1080}
    },
    {
      title: "Binary Fringes - Macrame Wall hanging Mini",
      images: [{ src: "data/items/WH0010/img1.jpg" }, { src: "data/items/WH0010/img2.jpg" }, { src: "data/items/WH0010/img3.jpg" }, 
      { src: "data/items/WH0010/img4.jpg" },  { src: "data/items/WH0010/img5.jpg" },  { src: "data/items/WH0010/img6.jpg" }],
      description: "Small spaces can use such Mini Wall hangings to look alive. Especially when the fringe is so soft, it adds to further character and coziness.\
      It's one of the perfect options to add to the kid's rooms as well as gift someone.\
      Message me to check other colour options, that are available with slightly additional fee.",
      materials: ["Cotton", "wooden dowel", "Dyed cotton"],
      dimentions: {height: "10 Inches", width: "10 Inches"},
      tags: ["handmade", "wall-hanging"],
      price: "700",
      id: "WH0010" ,
      cover: { src: 'data/items/WH0010/img1.jpg' , width: 1080, height: 1080}
    },
    {
      title: "Mustard Heart - Macrame Wall hanging Large",
      images: [{ src: "data/items/WH0008/img1.jpg" }, { src: "data/items/WH0008/img2.jpg" }, { src: "data/items/WH0008/img3.jpg" }, { src: "data/items/WH0008/img4.jpg" }],
      description: "An elegant design created with a dash of mustard flavour added to it. This intricate piece is made on teak wood, by using the chunky cotton, to keep the minute details intact while bringing the super soft feels. And the mustard tassel brings that little dose of warmth and a joyous feel, that makes you feel at home.\
      Personalization in terms of size, colour and tassel colour are available. message me to discuss further.",
      materials: ["Cotton", "wooden dowel", "Dyed cotton", "Chunky cotton", "teak wood"],
      dimentions: {height: "2.5 Feet", width: "2.5 Feet"},
      tags: ["handmade", "wall-hanging"],
      price: "6700",
      id: "WH0008" ,
      cover: { src: 'data/items/WH0008/img1.jpg' , width: 768, height: 1024}
    },
    {
      title: "Boho Dreams - Macrame Dreamcatcher",
      images: [{ src: "data/items/DC0001/img1.jpg" }, { src: "data/items/DC0001/img2.jpg" }, { src: "data/items/DC0001/img3.jpg" }],
      description: "A dream-catcher, created by Knotty Fibres, that contains so many details and earthy elements. With 3 different type of wooden beads adorning the piece throughout. The Jute used here, makes it shine when light hits it.\
      Personalization options in size and colour are available. Message me to discuss the same.",
      materials: ["Cotton", "Jute", "Wooden beads", "Steel ring"],
      dimentions: {height: "30 Inches", width: "9 Inches"},
      tags: ["handmade", "dream-catcher"],
      price: "1800",
      id: "DC0001" ,
      cover: { src: 'data/items/DC0001/img1.jpg' , width: 960, height: 1280}
    },
    {
      title: "Lotus - Macrame Dreamcatcher",
      images: [{ src: "data/items/DC0002/img1.jpg" }, { src: "data/items/DC0002/img2.jpg" }, { src: "data/items/DC0002/img3.jpg" }, { src: "data/items/DC0002/img4.jpg" }],
      description: "A Lotus dreamcatcher, created to meet your need for that simple yet charming piece, to bring the peaceful vibes to your space. The ring is finished with a baby pink synthetic rope, with a metallic finish, to provide a subtle hue to the piece.\
      Message me for personalization options.",
      materials: ["Cotton", "Steel ring", "Synthetic cord"],
      dimentions: {height: "23 Inches", width: "9 Inches"},
      tags: ["handmade", "dream-catcher"],
      price: "640",
      id: "DC0002" ,
      cover: { src: 'data/items/DC0002/img1.jpg' , width: 1908, height: 1908}
    },
    {
      title: "Mini Rainbow - Macrame Wall hanging",
      images: [{ src: "data/items/WH0007/img1.jpg" }, { src: "data/items/WH0007/img2.jpg" }, { src: "data/items/WH0007/img3.jpg" }, 
      { src: "data/items/WH0007/img4.jpg" }, { src: "data/items/WH0007/img5.jpg" }, { src: "data/items/WH0007/img6.jpg" }],
      description: "Rainbows are everyone's favourite. They bring a smile to the face of kids and adults with equal amount of joy.\
      It's time you have your own Rainbow to spruce up your place. This little one meets that requirement by fitting in even small spaces like your study table or that empty nook in your den. The added beauty is that the yarn is hand-dyed, so the colors don't appear to be too much.\
      Also, this item belongs to the category of \"products made up of completely natural fibres\", in my shop.",
      materials: ["Cotton", "Dyed yarn", "Wooden dowel"],
      dimentions: {height: "8.5 Inches", width: "8.2 Inches"},
      tags: ["handmade", "wall-hanging"],
      price: "975",
      id: "WH0007" ,
      cover: { src: 'data/items/WH0007/img1.jpg' , width: 1020, height: 1020}
    },
    {
      title: "Tree of Life - Macrame Dreamcatcher",
      images: [{ src: "data/items/DC0004/img1.jpg" }, { src: "data/items/DC0004/img2.jpg" }, { src: "data/items/DC0004/img3.jpg" }],
      description: "To me, nature is the ultimate representative of something bigger, beautiful and resilient, that we would keep exploring but can never understand completely. It is always so humbling. And Trees are one of the most important part of the same in our lives, & in so many ways. They keep giving so much without asking for anything. If that's not unconditional love then I don't know what is. And in honor of that, here's my little attempt to blend the nature and my passion for making, in the form of this \"Tree of Life\" dream-catcher, with 3D design elements. As alive as I could make it :)\
      Different size options are available, message me to know more.",
      materials: ["Cotton", "Steel", "Jute"],
      dimentions: {height: "25 Inches", width: "12 Inches"},
      tags: ["handmade", "dream-catcher"],
      price: "1875",
      id: "DC0004" ,
      cover: { src: 'data/items/DC0004/img2.jpg' , width: 794, height: 794}
    },
    {
      title: "Plant Basket - Macrame Plant Hanger",
      images: [{ src: "data/items/PH0004/img1.jpg" }, { src: "data/items/PH0004/img2.jpg" }],
      description: "Bring the cute, rustic charm to your walls or those corners that you can't decide what to add there, by adding this cute basket shaped plant hanger. Put a small creeper or some dried flowers to add some colour. Or maybe put a jar with a tea-light to brighten up the space. The intricate design of this basket will sure turn some heads.",
      materials: ["Cotton linen mix"],
      dimentions: {height: "28 Inches", width: "5 Inches"},
      tags: ["handmade", "plant-hanger"],
      price: "1050",
      id: "PH0004" ,
      cover: { src: 'data/items/PH0004/img1.jpg' , width: 794, height: 794}
    },
    {
      title: "Double Decker Plant Hanger",
      images: [{ src: "data/items/PH0005/img1.jpg" }],
      description: "A double level macrame plant hanger to help you add the greens in your space. This simplistic design will keep that minimalistic touch going, to blend with any type of decor that suits your style.\
      Available in 2 different type of materials - Cotton and Jute. Colour customization is also available, with little customization fee. Please get in touch to know more.",
      materials: ["Cotton", "Synthetic cord", "steel ring"],
      dimentions: {height: "60 Inches", width: "8 Inches"},
      tags: ["handmade", "plant-hanger"],
      price: "1200",
      id: "PH0005" ,
      cover: { src: 'data/items/PH0005/img1.jpg' , width: 794, height: 1204}
    }
  ];

  export default items;