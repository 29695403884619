
import React from 'react';
import { Checkbox } from "@material-ui/core";
import axios from "axios";

class BannerUpload extends React.Component {
    constructor(props) {
      super(props);
      this.state = {name: '', isLive: false, link: ''};

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);

      this.form = (<form onSubmit={this.handleSubmit}>
        <label>
          Name:
          <input type="text" name="name" onChange={this.handleChange}/>
        </label>
        <label>
          isLive:
          <input type="checkbox" name="isLive" onChange={this.handleChange}/>
        </label>
        <label>
          link:
          <input type="text" name="link" onChange={this.handleChange}/>
        </label>
        <label>
          <input type="file" name="file" onChange={this.handleChange}/>
        </label>
        <input type="submit" value="Submit"/>
      </form>);
    }

    handleChange(event) {
        console.log("state is: " , this.state);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        console.log("This event " , event.target);
        console.log("value: " + value + " name: " + name );
    
        this.setState({
          [name] : value
        });

        console.log("state is: " , this.state);
    }
  
    handleSubmit(event) {
      event.preventDefault();
      console.log('Data submitted: ', this.form);
      var state = {name: this.form.name};
      console.log(state);
      axios.post('http://127.0.0.1:8080/be/banners', this.state);
    }
  
    render() {
      return this.form;
    }
  }

export default function BannerManager() {
  return (
    <div>
      <h1>Create Banner</h1>
      <BannerUpload />
    </div>
  );
}