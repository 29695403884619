import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import logo from '../kflogo.png';

const useStyles = makeStyles({
  card: {
    display: 'flex',
    padding: "20px",
    margin: "20px"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function About() {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <div>
        <h1>About Us</h1>
        <hr  style={{ color: '#000000', backgroundColor: '#000000', height: .5, borderColor : '#000000'}}/>
        <div style={{display: 'flex', padding: '10px', alignContent: "center"}}>
            <Card className={classes.card}>
                <CardContent>
                    <img src={logo} className="App-logo" alt="logo" />
                    <Typography variant="h2" component='h2'>
                      Knotty Fibres
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      Unique Macrame products that are a piece of "heartwork"
                    </Typography>
                    <Typography variant="h5" component="h3">
                    Introducing "Knotty Fibres", the brand that explores the ever evolving arena of Fibre-art. Handmade with love, in India.
                    </Typography>
                </CardContent>
            </Card>
        </div>
    </div>
  );
}
