import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Home from '@material-ui/icons/Home';
import MenuBook from '@material-ui/icons/MenuBook';
import Store from '@material-ui/icons/Store';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Info from '@material-ui/icons/Info';
import Phone from '@material-ui/icons/Phone';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer({open, handleDrawerParentClose}) {
  const classes = useStyles();
  const theme = useTheme();
  
  function handleDrawerClose(){
    handleDrawerParentClose.call();
  }

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerParentClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
            <ListItem button component={Link} to='/' key="Home" >
                <ListItemIcon><Home/></ListItemIcon>
                <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to='/catalogue' key="Catalogue">
              <ListItemIcon><MenuBook/></ListItemIcon>
              <ListItemText primary="Catalogue" />
            </ListItem>
            <ListItem button component={Link} to='/events' key="Events">
              <ListItemIcon><CalendarToday/></ListItemIcon>
              <ListItemText primary="Events" />
            </ListItem>
            <ListItem button component={Link} to='/store' key="Store">
              <ListItemIcon><Store/></ListItemIcon>
              <ListItemText primary="Store" />
            </ListItem>
            <ListItem button component={Link} to='/about' key="About Us">
              <ListItemIcon><Info/></ListItemIcon>
              <ListItemText primary="About Us" />
            </ListItem>
            <ListItem button component={Link} to='/contact' key="Contact Us">
              <ListItemIcon><Phone/></ListItemIcon>
              <ListItemText primary="Contact Us" />
            </ListItem>
        </List>
      </Drawer>
    </div>
  );
}