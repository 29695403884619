import React from 'react';
import BannerManager from './banners';
import {Route, Link } from "react-router-dom";

export default function AdminHome() {
    return <div>
                <h2>Admin</h2>
                <ul>
                    <li><h3>Banner</h3></li>
                    <li><Link to="/admin/banners">Banners</Link></li>
                </ul>
                <Route path="/admin/banners" component={BannerManager} />
            </div>;
}