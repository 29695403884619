import React, { Component, useCallback } from 'react';
import Gallery from "react-photo-gallery";
import GalleryItem from './gallery_item';
import items from '../data/items';

export default class ItemsGallery extends Component {

    constructor(props) {
        super(props);
        items.map((element, index) => {
          element.src = element.cover.src;
          element.width = element.cover.width;
          element.height = element.cover.height;
          element.openState = false;
        })
        this.state = {items};
        this.containerLeft = 0;
        this.containerWidth = 1;
        this.selectedItemId = null;
        this.containerRef = React.createRef();
        this.renderCallback = this.renderCallback.bind(this);
        this.clickCallback = this.clickCallback.bind(this);
      }

    clickCallback(selectedItemId){
      var items = this.state.items;
      items.map((element, index) => {
        if(element.id === selectedItemId){
          element.openState = true;
        }
        if (element.id === this.selectedItemId){
          element.openState = false;
        }
      })
      this.selectedItemId = selectedItemId;
      this.setState({items});
    }

    renderCallback({index, left, top, key, photo}) {
        if(this.containerRef.current){
            const containerRect = this.containerRef.current.getBoundingClientRect();
            if(containerRect.left !== this.containerLeft){
                this.containerLeft = containerRect.left;
            }
            if(containerRect.width !== this.containerWidth){
                this.containerWidth = containerRect.width;
            }
        }
        return (<GalleryItem index={index} left={this.containerLeft} width={this.containerWidth} 
        itemDetails={photo} clickListner={this.clickCallback}></GalleryItem>);
    }

    render() {
        var items = [...this.state.items];
        return (<div ref={this.containerRef}><Gallery photos={items} renderImage={this.renderCallback}/></div>);
    }
}