import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import ImageGallery from './image_gallery';

export class ItemContainer extends React.Component {
    constructor(props) {
      super(props);
    
      this.state = {
        left: 0
      };

      this.boxRef = React.createRef();
    }

    componentDidMount() {
        const left = this.props.parentLeft - this.boxRef.current.getBoundingClientRect().left + "px";
        this.setState(state => {
            return {left: left};
          });
      }
  
    render() {   
        console.log("Rendering: " + this.props.itemDetails.id); 
        var dimensionStr = "";
        if(this.props.itemDetails.dimentions.hasOwnProperty("width")) {
            dimensionStr += "Width: " + this.props.itemDetails.dimentions.width + " ";
        }
        if(this.props.itemDetails.dimentions.hasOwnProperty("height")) {
            dimensionStr += "Height: " + this.props.itemDetails.dimentions.height + " ";
        }
        if(this.props.itemDetails.dimentions.hasOwnProperty("depth")) {
            dimensionStr += "Depth: " + this.props.itemDetails.dimentions.depth + " ";
        }
        return (
        <div ref={this.boxRef} style={{width: this.props.parentWidth, position: 'relative', left: this.state.left, backgroundColor: "#efdad4"}}>
            <div style={{display: 'flex', padding: '10px'}}>
                <div style={{float: 'left', display: 'flex', flex: '0 0 70%'}}>
                    <ImageGallery images={this.props.itemDetails.images}/>
                </div>
                <div style={{display: 'inline-block', textAlign: "left", padding: "10px"}}>
                    <h1>{this.props.itemDetails.title}</h1>
                    <h3>Price &#8377; {this.props.itemDetails.price}</h3>
                    <h3>Materials</h3>
                    <p>{this.props.itemDetails.materials.join(',')}</p>
                    <h3>Dimentions</h3>
                    <p>
                        {dimensionStr}
                    </p>
                    <h3>Tags</h3>
                    <p>{this.props.itemDetails.tags.join(',')}</p>
                    <h4>Description</h4>
                    <p>{this.props.itemDetails.description}</p>
                </div>
            </div>
        </div>
        );
    }
  }

export default function GalleryItem ({left, index, width, itemDetails, clickListner}){
    return (
    <Collapsible containerElementProps={{style: {width: itemDetails.width, margin: '2px'}}} style={{width: itemDetails.width}}
     trigger={<img {...itemDetails}></img>} overflowWhenOpen='visible' triggerStyle={{margin: '2px'}} open={itemDetails.openState}
     handleTriggerClick={clickListner} accordionPosition={itemDetails.id}>
         <ItemContainer parentLeft={left} parentWidth={width} itemDetails={itemDetails}></ItemContainer>   
    </Collapsible>
    );
}