import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Mail from '@material-ui/icons/Mail';
import Typography from '@material-ui/core/Typography';
import Instagram from '@material-ui/icons/Instagram';
import Facebook from '@material-ui/icons/Facebook';

const useStyles = makeStyles({
  card: {
    display: 'flex',
    padding: "20px",
    margin: "20px"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Contact() {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <div>
        <h1>Contact Us</h1>
        <hr  style={{ color: '#000000', backgroundColor: '#000000', height: .5, borderColor : '#000000'}}/>
        <div style={{display: 'flex', padding: '10px', alignContent: "center"}}>
            <Card className={classes.card}>
                <CardContent>
                    <Mail/>
                    <Typography className={classes.pos} color="textSecondary">
                        mail us your queries, custom orders at
                    </Typography>
                    <Typography variant="h5" component="h2">
                        shop@knottyfibres.com
                    </Typography>
                </CardContent>
            </Card>
            <Card className={classes.card}>
                <CardContent>
                    <Instagram/>
                    <Typography className={classes.pos} color="textSecondary">
                        follow and ping us on Instagram
                    </Typography>
                    <Typography variant="h5" component="h2">
                        <a href='https://www.instagram.com/knottyfibres/'>Knotty fibres</a>
                    </Typography>
                </CardContent>
            </Card>
            <Card className={classes.card}>
                <CardContent>
                    <Facebook/>
                    <Typography className={classes.pos} color="textSecondary">
                        follow and ping us on facebook
                    </Typography>
                    <Typography variant="h5" component="h2">
                        <a href='https://www.facebook.com/Knottyfibres/'>@Knottyfibres</a>
                    </Typography>
                </CardContent>
            </Card>
        </div>
    </div>
  );
}
